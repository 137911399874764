<template>
    <b-container :fluid="isFluid">
        <b-row v-if="formLoading || asyncLoading">
            <b-col sm="12" style="min-height: min(400px, 50vh)">
                <LoadingView />
            </b-col>
        </b-row>
        <b-col sm="12" v-else-if="formError" class="form-error-view">
            <i class="fa fa-exclamation-triangle text-warning" style="font-size: 50px"></i>
            <h1 class="mt-4" style="max-width: 80vw">
                {{ (formError.error && formError.error.message) || $l("platon.form_error_text", "Хатолик") }}
            </h1>

            <div>
                <platon-btn
                    variant="primary"
                    class="mt-3 btn-lg"
                    icon="fas fa-sync-alt"
                    :loading="formLoading"
                    @click.native="loadForm()"
                >
                    {{ $l("platon.reload_page", "Сахифани қайта юклаш") }}
                </platon-btn>
            </div>
        </b-col>

        <b-row
            v-else-if="loadFormEndpoint"
            :class="{ 'p-3': !isModal }"
            class="platon-form"
            :form-name="localRoute.params.name"
            :form-id="formMeta && formMeta.id"
        >
            <ChimeraErrorsView
                v-if="loadFormEndpoint.status >= 400"
                style="height: 400px; width: 100%"
                :endpoint="loadFormEndpoint"
                icon="mdi mdi-window-restore"
                :access-denied-text="$l('platon.form_has_no_access', 'Бу формага кўришга рўхсат берилмаган')"
                :not-found-text="$l('platon.form_not_found', 'Форма топилмади')"
            />

            <b-col
                :sm="gridOptions.gridSize"
                :offset="gridOptions.offset"
                class="pt-2"
                :class="{ 'form-layout': !isModal }"
                v-else-if="formMeta"
            >
                <v-style v-if="formMeta.css">{{ formMeta.css }}</v-style>

                <div class="pt-1 pb-2 m-0" v-if="!isModal">
                    <h3 class="text-center m-0" v-html="formHeader"></h3>
                </div>

                <form v-if="isFormReady" ref="form" class="form-row" @submit.prevent="saveForm" autocomplete="off">
                    <div v-if="isActionBarVisible('top')" class="m-2 width-100p" style="width: 100%">
                        <FormActionButtonBar
                            :form="thisForm"
                            :buttons="formElementContainers.formActionButtons"
                            position="top"
                        />
                    </div>

                    <PlatonFormElement
                        :item="f"
                        ref="fieldset"
                        :key="f.id"
                        v-for="f in formElementContainers.containers"
                    />

                    <div v-if="isActionBarVisible('bottom') && !isModal" class="m-2 width-100p" style="width: 100%">
                        <FormActionButtonBar
                            :form="thisForm"
                            :buttons="formElementContainers.formActionButtons"
                            position="bottom"
                        />
                    </div>
                </form>

                <hr class="divider" v-if="!isModal" />

                <PlatonFormAdminFooter class="mb-3" v-if="!isModal" :form="thisForm" />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { AxiosResponse } from "axios"
import LoadingView from "@Platon/components/extended/LoadingView.vue"
import CodeInjectableMixin from "@Platon/mixins/CodeInjectableMixin"
import TitleMixin from "@Platon/mixins/TitleMixin"
import ModalMixin from "@Platon/mixins/ModalMixin"
import NavigationMixin from "@Platon/mixins/NavigationMixin"
import EventsMixin, { EventNames } from "@Platon/mixins/EventsMixin"
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"
import ToastMixin from "@Platon/mixins/ToastMixin"
import PlatonFormAdminFooter from "@Platon/components/form/PlatonFormAdminFooter.vue"
import { DevLog, IS_DEV } from "@Platon/const"
import PlatonFormScriptHelperMixin from "@Platon/components/form/PlatonFormScriptHelperMixin"
import PlatonFormEvents from "@Platon/components/form/PlatonFormEvents"
import ChimeraErrorsView from "@Platon/components/misc/ChimeraErrorsView.vue"
import groupBy from "lodash.groupby"
import sortBy from "lodash/sortBy"
import ActionBtn from "@Platon/components/extended/ActionBtn.vue"
import FormActionButtonBar from "@Platon/components/form/FormActionButtonBar.vue"
import { runScopeFn, scopeFn, wrapWithParamsAndArgs } from "@Platon/core/condition"
import IconPickerMixin from "@Platon/mixins/IconPickerMixin"
import qs from "qs"
import { cast } from "@Platon/core/helpers"
import PublicPageMixin from "@Platon/mixins/PublicPageMixin"
import ModalRouteMixin from "@Platon/mixins/ModalRouteMixin"
import PluginEvents from "@Platon/core/plugins/PluginEvents"

const FormElementContainers = ["fieldset", "tab_container", "html"]
let FormId = 0

export default {
    name: "PlatonFormView",

    components: { FormActionButtonBar, ActionBtn, ChimeraErrorsView, PlatonFormAdminFooter, LoadingView },

    mixins: [
        CodeInjectableMixin,
        IconPickerMixin,
        NavigationMixin,
        TitleMixin,
        ModalMixin,
        ModalRouteMixin,
        EventsMixin,
        PermissionMixin,
        ToastMixin,
        PlatonFormScriptHelperMixin,
        PlatonFormEvents,
        PublicPageMixin
    ],

    data() {
        return {
            formLocalId: ++FormId,
            dataHolder: {},
            state: {},
            validationElements: new Set(),
            hiddenElements: new Map(),
            elements: new Map(),
            transformData: (data) => data,
            canRenderSystemButton: (btn) => {
                return true
            },

            defaultReadOnly: {},
            formError: null,
            isFormReady: false,
            asyncLoading: false,
            asyncFetchData: null,
            formMeta: null,
            formLoading: false,
            loadFormEndpoint: null,
            refreshOnSave: true
        }
    },

    provide() {
        return {
            form: this
        }
    },

    beforeMount() {
        this.loadForm()
    },

    mounted() {
        this.$on("formSetData", this.formSetData)
        window.addEventListener(EventNames.FORM_SAVED, this.onFormSaved)
        // document.addEventListener("keydown", this.keyDown)
        this.$plugins.triggerEvent(PluginEvents.OnFormRead, {
            viewingElement: this.localRoute.params.name,
            isFormPublic: this.isPublicPlatonComponent,
            isFormModal: this.isModal,
            queryParams: this.$route.query
        })
    },
    beforeDestroy() {
        this.$off("formSetData", this.formSetData)
        window.removeEventListener(EventNames.FORM_SAVED, this.onFormSaved)
        // window.removeEventListener("keydown", this.keyDown)
    },

    methods: {
        keyDown(event) {
            this.keyDownSave(event)
            this.keyDownClose(event)
        },
        keyDownSave(e) {
            const isCommandOrCtrl = e.ctrlKey || e.metaKey
            const isSKeyPressed = e.key === "s" || e.keyCode === 83
            if (isCommandOrCtrl && isSKeyPressed) {
                e.preventDefault()
                this.saveForm(_, { isLoading: true })
            }
        },
        keyDownClose(e) {
            const isSKeyPressed = e.key === "Escape" || e.keyCode === 27
            if (isSKeyPressed) {
                e.preventDefault()
                window.close()
            }
        },
        async saveFormRequest(sendData) {
            const method = this.cloneMode ? "PATCH" : this.editMode ? "PUT" : "POST"

            const response = await this.$http({
                url: this.formUrl,
                method,
                data: sendData
            })

            const { data } = response

            if (this.localRoute.params.id) {
                this.$plugins.triggerEvent(PluginEvents.OnFormUpdate, {
                    updatedElement: `${this.localRoute.params.name}/${this.localRoute.params.id}`,
                    isFormPublic: this.isPublicPlatonComponent,
                    isFormModal: this.isModal,
                    queryParams: this.$route.query
                })
            } else {
                this.$plugins.triggerEvent(PluginEvents.OnFormInsert, {
                    createdElementPath: `${this.localRoute.params.name}`,
                    isFormPublic: this.isPublicPlatonComponent,
                    isFormModal: this.isModal,
                    queryParams: this.$route.query
                })
            }

            if (this.cloneMode) {
                const link = this.cloneRedirectUrl(data)

                if (link) await this.navigateTo(link)
            }

            return data
        },

        /**
         * Used to make link, may be overwritten by form js
         *
         * @param {Record<string, any>} data returned from clone response
         * @returns {?string} link to redirect
         */
        cloneRedirectUrl(data) {
            const routeName = this.localRoute.params.name
            const cloneTarget = this.localRoute.query._cloneTarget || "blank"
            const cloneKey = this.localRoute.query._cloneKey || "name"
            const clonePrefix = this.localRoute.query._clonePrefix || ""
            const name = data[cloneKey]

            return `${clonePrefix}${routeName}/${name}?_target=${cloneTarget}`
        },

        async loadForm() {
            const assignEndpoint = (response) => {
                Object.defineProperty(response, "loading", {
                    get: () => {
                        return this.formLoading
                    }
                })

                Object.defineProperty(response, "fetch", {
                    get: () => {
                        return this.loadForm
                    }
                })

                this.loadFormEndpoint = Object.assign(response)
            }

            let url = this.formUrl

            /*### Cloning logic ###*/
            let urlData = this.$router.resolve(this.formUrl)
            let clone = urlData.resolved.query["_clone"]

            if (clone) {
                if (IS_DEV) console.log("Must be clone", clone)

                let cloneRoute = urlData.route
                cloneRoute.params.id = clone.toString()

                url = this.$router.resolve(cloneRoute).route.fullPath
            }
            /* ################### */

            try {
                this.formLoading = true
                this.formError = null
                this.asyncLoading = false
                this.isFormReady = false

                const response = await this.$http.get(url)
                assignEndpoint(response)

                /** @type PlatonForm */
                const data = response.data
                this.formMeta = data

                this.loadFormDataFromRemote(data)

                if (data.js) {
                    await this.setFormReady()
                    this.notifyFormLoad()
                    setTimeout(async () => {
                        this.$clearEvents()
                        this.handleFormJs(data.js)
                        await this.handleAsyncFetchData()
                    }, 100)
                } else {
                    await this.setFormReady()
                    this.handleAsyncFetchData().then(() => {
                        this.notifyFormLoad()
                    })
                }

                this.$nextTick(() => {
                    for (let formElement of this.formElements) {
                        if (formElement.id) this.defaultReadOnly[formElement.id] = formElement.isReadOnly
                    }
                })
            } catch (err) {
                if (err.response) {
                    assignEndpoint(err.response)
                }

                this.formError = err
                this.asyncLoading = false
            } finally {
                this.formLoading = false
            }
        },

        async deleteRecord() {
            let queryParams = qs.stringify(this.localRoute.query, { arrayFormat: "brackets" })
            let url =
                `forms/${this.localRoute.params.name}/${this.localRoute.params.id}` +
                (queryParams.length > 0 ? `?${queryParams}` : "")
            this.$plugins.triggerEvent(PluginEvents.OnFormDelete, {
                deletedElement: `${this.localRoute.params.name}/${this.localRoute.params.id}`,
                isFormPublic: this.isPublicPlatonComponent,
                isFormModal: this.isModal,
                queryParams: this.$route.query
            })

            await this.$http.delete(this.addPublicRoutePrefix(url))
        },

        async hardDeleteRecord() {
            let queryParams = qs.stringify({ ...this.localRoute.query, hard_delete: true }, { arrayFormat: "brackets" })
            let url =
                `forms/${this.localRoute.params.name}/${this.localRoute.params.id}` +
                (queryParams.length > 0 ? `?${queryParams}` : "")
            this.$plugins.triggerEvent(PluginEvents.OnFormHardDelete, {
                hardDeletedElement: `${this.localRoute.params.name}/${this.localRoute.params.id}`,
                isFormPublic: this.isPublicPlatonComponent,
                isFormModal: this.isModal,
                queryParams: this.$route.query
            })

            await this.$http.delete(this.addPublicRoutePrefix(url))
        },

        async restoreRecord() {
            let queryParams = qs.stringify(this.localRoute.query, { arrayFormat: "brackets" })
            let url =
                `forms/${this.localRoute.params.name}/${this.localRoute.params.id}/restore` +
                (queryParams.length > 0 ? `?${queryParams}` : "")

            await this.$http.get(this.addPublicRoutePrefix(url))
        },
        onFormSaved({ detail }) {
            if (detail && detail.formLocalId === this.formLocalId && this.refreshOnSave) this.loadForm()
        },

        notifyFormLoad() {
            this.$emit("loaded", this)
            this.onFormLoad(this.dataHolder)
        },

        async setFormReady() {
            this.isFormReady = true
            await this.$nextTick()
        },

        notifyTable() {
            if (this.isModal || this.isTargetSelf) {
                this.publishSaveFormEvent(window, this.formMeta.name, { formLocalId: this.formLocalId })
            }

            if (window.opener) {
                this.publishSaveFormEvent(window.opener, this.formMeta.name, { formLocalId: this.formLocalId })
            }
        },

        /**
         * @param {string} key
         * @param {any} value
         * @param notify
         */
        formSetData(key, value, notify = true) {
            /**
             * @type PlatonFormElement
             */
            let element = this.formElementsWithKey[key]

            if (element && element.dataField) {
                value = cast(element.dataField, element.type, value)
            }

            this.$set(this.dataHolder, key, value)

            DevLog("change form data", key, value)

            if (notify) this.onFormDataSet(key, value)
        },

        getFormData() {
            let postData = {}

            for (let key of Object.keys(this.dataHolder)) {
                // if formElement not found or disabled, just skip it
                if (!this.formElementsWithKey[key] || this.formElementsWithKey[key].isDisabled) {
                    continue
                }

                if (this.hiddenElements.has(key)) {
                    continue
                }

                postData[key] = this.dataHolder[key]
            }

            return postData
        },

        async saveForm(form, button) {
            let isValid = true

            for (let validationElement of this.validationElements) {
                if (validationElement.$parent.shouldDisplay && !validationElement.validate()) {
                    if (IS_DEV) console.warn(validationElement)

                    validationElement.markAsDirty()
                    isValid = false
                }
            }

            if (isValid) {
                let processRequest = await (this.editMode ? this.beforeEdit() : this.beforeCreate())

                if (processRequest === false) return

                if ((await this.beforeSave()) === false) {
                    return
                }

                if (this.cloneMode && (await this.beforeClone()) === false) {
                    return
                }

                if (button) button.isLoading = true

                try {
                    /** @type {AxiosResponse} */
                    let response = await this.saveFormRequest(this.transformData(this.getFormData()))
                    let id = response.id

                    await this.afterSave(id)
                    await (this.editMode ? this.afterEdit(id) : this.afterCreate(id))
                    this.cloneMode && (await this.afterClone())

                    this.greenToast(this.$l("platon.form_saved", "Маълумотлар сақланди"))

                    this.notifyTable()

                    this.handleNextLink(id)
                } catch (error) {
                    if (IS_DEV) console.log(error)

                    let message = error.message

                    if (error.response && error.response.data) {
                        if (error.response.data.message) {
                            message = error.response.data.message
                        }

                        const platonFieldErrors = error.response.data.errors

                        if (platonFieldErrors && Object.keys(platonFieldErrors).length > 0) {
                            this.$emit("remoteErrors", platonFieldErrors)
                        }
                    }

                    this.errorToast(message || this.$l("platon.form_server_error", "Серверда хатолик"))
                } finally {
                    if (button) button.isLoading = false
                }
            } else {
                this.dangerToast(
                    this.$l("platon.attention", "Диққат"),
                    this.$l("platon.form_check_filled", "Майдонлар тўғри тўлдирилганлигини текширинг")
                )
            }
        },

        exitForm() {
            this.$emit("close")

            if (!this.isModal) {
                if (window.history.length > 1) {
                    window.history.back()
                } else {
                    window.close()
                }
            }
        },

        async deleteForm() {
            try {
                if ((await this.beforeDelete()) === false) return

                await this.showConfirmDialog(
                    this.$l("platon.attention", "Диққат"),
                    this.$l("platon.form_confirm_data_delete", "Ўчиришни хоҳлайсизми ?")
                )

                await this.deleteRecord()

                await this.afterDelete()

                this.notifyTable()

                this.greenToast(this.$l("platon.form_data_deleted", "Маълумотлар ўчирилди"))

                if (this.isPublicPlatonComponent) {
                    this.switchToCreateMode()
                } else {
                    this.exitForm()
                }
            } catch (error) {
                this.errorToast(error.message)

                if (IS_DEV) console.log(error)
            }
        },

        async hardDeleteForm() {
            try {
                await this.showConfirmDialog(
                    this.$l("platon.attention", "Диққат"),
                    this.$l(
                        "platon.form_confirm_data_hard_delete",
                        "Ўчиришни хоҳлайсизми? Ўчирилган маълумотларни тиклаб бўлмайди"
                    )
                )

                if ((await this.beforeHardDelete()) === false) return

                await this.hardDeleteRecord()

                await this.afterHardDelete()

                this.notifyTable()

                this.greenToast(this.$l("platon.form_data_deleted", "Маълумотлар ўчирилди"))

                this.exitForm()
            } catch (e) {
                this.errorToast(e.message)

                if (IS_DEV) console.log(e)
            }
        },

        async restoreForm() {
            try {
                await this.showConfirmDialog(
                    this.$l("platon.attention", "Диққат"),
                    this.$l("platon.confirm_restore", "Ўчирилган маълумотни қайта тикламоқчимисиз ?")
                )

                if ((await this.beforeRestore()) === false) return

                await this.restoreRecord()

                await this.afterRestore()

                this.notifyTable()

                this.greenToast(this.$l("platon.form_data_restored", "Маълумотлар қайта тикланди"))

                this.reloadFormData()
            } catch (e) {
                if (IS_DEV) console.log(e)
            }
        },

        reloadFormData() {
            this.loadForm()
        },

        /**
         * @param {number|undefined} id
         *
         * @desc Yangi ma'lumot yaratilinganda yoki saqlanganda id keladi, shu id bo'yicha hozirgi formani qayta yuklash k-k
         */
        switchToEditMode(id) {
            if (!this.editMode || id === undefined) {
                // navigate to edit mode
                let queries = this.localRoute.query

                // remove clone param if it was cloned
                delete queries["_clone"]

                // if in modal mode don't change url
                let to = {
                    name: this.localRoute.name,
                    query: queries,
                    params: {
                        name: this.localRoute.params.name,
                        id
                    }
                }

                this.localRoute = this.$router.resolve(to).route

                if (!this.isModal) {
                    this.$router.replace(to)
                    return
                }
            }
        },

        switchToCreateMode() {
            this.switchToEditMode(undefined)
        },

        /**
         * @param {Number} recordId
         */
        handleNextLink(recordId) {
            let nextLink = this.localRoute.query["_nextLink"] || this.formMeta.nextLink

            if (nextLink === "back") {
                this.exitForm()
            } else if (!nextLink || nextLink === "refresh" || nextLink === "") {
                // if it is not in edit mode after saving switching edit form route
                if (!this.editMode) {
                    this.switchToEditMode(recordId)
                }
            } else {
                if (this.isModal) this.$emit("close")
                else if (this.isTargetSelf) {
                    this.$router.back()
                }

                this.navigateTo(nextLink, {}, window.parent)
            }
        },

        /**
         * @param {PlatonForm} form
         */
        loadFormDataFromRemote(form) {
            this.$set(this, "dataHolder", form.formData)
        },

        addFormSystemButton: function (buttonContainer) {
            buttonContainer.push({
                projectId: -1,
                buttonText: this.$l("platon.form_save", "Сақлаш"),
                buttonIcon: "fa fa-save",
                position: "bottom-left",
                cssClass: "save-btn",
                js: this.saveForm,
                sortOrder: -20,
                displayCondition: () => {
                    return (
                        this.canRenderSystemButton("save") &&
                        ((this.editMode && this.formMeta.canEdit) || (!this.editMode && this.formMeta.canCreate))
                    )
                }
            })

            buttonContainer.push({
                projectId: -1,
                buttonText: this.$l("platon.form_close", "Чиқиш"),
                buttonIcon: "fa fa-times",
                position: "bottom-left",
                js: this.exitForm,
                cssClass: "btn-warning close-btn",
                sortOrder: -10,
                displayCondition: () => {
                    return (
                        this.canRenderSystemButton("close") &&
                        !(
                            (this.localRoute.query["_target"] === "blank" || !this.localRoute.query["_target"]) &&
                            !window.opener
                        )
                    )
                }
            })

            buttonContainer.push({
                projectId: -1,
                buttonText: this.$l("platon.form_restore", "Тиклаш"),
                buttonIcon: "fa fa-redo",
                position: "bottom-right",
                cssClass: "btn-info restore-btn",
                js: this.restoreForm,
                sortOrder: -10,
                displayCondition: () => {
                    return this.canRenderSystemButton("restore") && this.editMode && this.formMeta.canRestore
                }
            })

            buttonContainer.push({
                projectId: -1,
                buttonText: this.$l("platon.form_hard_delete", "Тўлиқ ўчириш"),
                buttonIcon: "fa fa-trash-alt",
                position: "bottom-right",
                cssClass: "btn-dark-red hard-delete-btn",
                js: this.hardDeleteForm,
                sortOrder: -20,
                displayCondition: () => {
                    return (
                        !this.cloneMode &&
                        this.canRenderSystemButton("hardDelete") &&
                        this.editMode &&
                        this.formMeta.canHardDelete
                    )
                }
            })
            //
            // if(this.localRoute.params.name == 'forms' || this.localRoute.params.name == 'tables'){
            // 	console.log('test',this.formMeta)
            // 	buttonContainer.push({
            // 		projectId: -1,
            // 		buttonText: this.$l('platon.form_clone', 'Клон қилиш'),
            // 		buttonIcon: 'fas fa-copy',
            // 		position: 'bottom-right',
            // 		cssClass: 'btn-warning clone-btn',
            // 		js: this.cloneForm,
            // 		sortOrder: -30,
            // 		displayCondition: () => {
            // 			return this.canRenderSystemButton('clone') && (this.editMode && this.formMeta.canClone)
            // 		}
            // 	})
            // }

            buttonContainer.push({
                projectId: -1,
                buttonText: this.$l("platon.form_delete", "Ўчириш"),
                buttonIcon: "fa fa-trash-alt",
                position: "bottom-right",
                cssClass: "btn-danger delete-btn",
                js: this.deleteForm,
                sortOrder: -30,
                displayCondition: () => {
                    return (
                        !this.cloneMode &&
                        this.canRenderSystemButton("delete") &&
                        this.editMode &&
                        this.formMeta.canDelete
                    )
                }
            })
        },

        /**
         * @param {PlatonFormElement[]} buttons
         */
        addDisplayConditionFnToButtons(buttons) {
            buttons.forEach((button) => {
                if (button.displayCondition && typeof button.displayCondition === "string") {
                    button.displayCondition = scopeFn(button.displayCondition, ["form", "data"])
                }
            })
        },

        runScopeArgs() {
            return {
                data: this.dataHolder,
                form: this
            }
        },

        isActionBarVisible(position) {
            let bar = this.formElementContainers.formActionButtons

            return bar[`${position}-left`] || bar[`${position}-right`]
        },

        async handleAsyncFetchData() {
            if (this.asyncFetchData && typeof this.asyncFetchData === "function") {
                try {
                    this.asyncLoading = true
                    await this.asyncFetchData()
                } catch (e) {
                    console.warn("Form handle async fetch data", e)
                    this.formError = e
                } finally {
                    this.asyncLoading = false
                }
            }
        },

        makeReadonly(isReadOnly = true) {
            for (let formElement of this.formElements) {
                this.$set(formElement, "isReadOnly", isReadOnly ? true : this.defaultReadOnly[formElement.id])
            }
        },

        /**
         * Return platon form element
         *
         * @param {string} dataField
         */
        getPlatonField(dataField) {
            /** @type Vue */
            const element = this.elements.get(dataField)

            if (element && element.$refs["element"]) {
                return element.$refs["element"]
            }

            return null
        },

        /**
         * @param {string|string[]} dataField
         */
        validateField(dataField) {
            const fields = Array.isArray(dataField) ? dataField : [dataField]

            for (let field of fields) {
                const el = this.getPlatonField(field)

                if (el && typeof el.validate === "function") {
                    el.validate()
                }
            }
        }
    },

    computed: {
        thisForm() {
            return this
        },

        isTargetSelf() {
            return this.localRoute.query["_target"] === "self"
        },

        isModal() {
            return !!this.route
        },

        editId() {
            return this.localRoute.params.id
        },

        editMode() {
            return !!this.localRoute.params.id || !!this.localRoute.query._clone
        },

        cloneMode() {
            return !!this.localRoute.query._clone
        },

        formUrl() {
            return this.localRoute.fullPath
        },

        /**
         * @return {PlatonFormElement[]}
         */
        formElements() {
            try {
                /**
                 * @param {Array} items
                 * @return {PlatonFormElement[]}
                 */
                const collect = (items) => {
                    return items.reduce((arr, x) => {
                        arr.push(x)

                        if (x && Array.isArray(x.children) && x.children.length > 0) {
                            arr.push(...collect(x.children))
                        }

                        return arr
                    }, [])
                }

                return collect(this.formMeta.elements)
            } catch (e) {
                console.warn(e)

                return []
            }
        },

        formElementContainers() {
            let containers = []
            const orphanElements = []
            const formActionButtons = []

            try {
                const elements = this.formMeta ? this.formMeta.elements.slice() : []

                for (let element of elements) {
                    if (!element) continue

                    if (element.type === "button" && element.position) {
                        formActionButtons.push(element)
                    } else if (FormElementContainers.includes(element.type)) {
                        containers.push(element)
                    } else {
                        orphanElements.push(element)
                    }
                }

                if (orphanElements.length > 0) {
                    containers.unshift({
                        type: "fieldset",
                        id: 0,
                        sortOrder: 0,
                        children: orphanElements
                    })

                    containers = sortBy(containers, "sortOrder")
                }
            } catch (e) {
                console.warn(e)
            }

            this.addDisplayConditionFnToButtons(formActionButtons)
            this.addFormSystemButton(formActionButtons)

            return {
                containers,
                formActionButtons: groupBy(sortBy(formActionButtons, "sortOrder"), "position")
            }
        },

        /**
         * @return {Object.<string, PlatonFormElement>}
         */

        formElementsWithKey() {
            try {
                return this.formElements.reduce((obj, x) => {
                    if (x && x.dataField) obj[x.dataField] = x

                    if (x && x.id) obj[x.id] = x

                    return obj
                }, {})
            } catch (e) {
                return {}
            }
        },

        params() {
            try {
                return this.formMeta.params
            } catch {
                return {}
            }
        },

        /**
         * @return {number}
         */
        gridOffset() {
            return this.formMeta.gridOffset || 0
        },

        gridOptions() {
            // if in modal disable grid
            if (this.isModal) {
                return {
                    offset: 0,
                    gridSize: 12
                }
            }

            return {
                offset: this.gridOffset,
                gridSize: 12 - this.gridOffset * 2
            }
        },

        isFluid() {
            return true
        },

        /**
         * @return {string}
         */
        formHeader() {
            return (this.editMode ? this.formMeta.editHeader : this.formMeta.addHeader) || "Форма"
        },

        /**
         * @return {string}
         */
        formTitle() {
            try {
                return (this.editMode ? this.formMeta.editTitle : this.formMeta.addTitle) || "Форма"
            } catch (e) {
                return "Form"
            }
        }
    },

    watch: {
        formTitle(val) {
            if (this.isModal) {
                this.$emit("formTitle", val)
            } else {
                this.setTitle(val)
            }
        }
    }
}
</script>

<style>
.form-error-view {
    min-height: 400px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
